import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './features/home/home.component';
import { PastisCrespinolComponent } from './features/pastis-crespinol/pastis-crespinol.component';
import { FooterComponent } from './features/footer/footer.component';
import { PointsVenteComponent } from './features/points-vente/points-vente.component';
import { RemerciementsComponent } from './features/remerciements/remerciements.component';
import { ContactComponent } from './features/contact/contact.component';
import { MentionsLegalesComponent } from './features/mentions-legales/mentions-legales.component';

import { ResponsiveService } from './services/responsive.service';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PastisCrespinolComponent,
    FooterComponent,
    PointsVenteComponent,
    RemerciementsComponent,
    ContactComponent,
    MentionsLegalesComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatRippleModule,
    MatDividerModule,
    MatTooltipModule,
    LeafletModule,
    HttpClientModule
  ],
  providers: [ResponsiveService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
